import React, { useState } from "react";

import { images } from "../../constants";
import { AppWrap } from "../../wrapper";
import { client } from "../../client";
import { BsTwitter, BsGithub, BsLinkedin } from "react-icons/bs";

import "./Footer.scss";

import { motion } from "framer-motion";
import { TypingText } from "../../components";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client
      .create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <h2 className="head-text">
        <TypingText title="Contact" />
        <hr className="brand-line"></hr>
      </h2>
      <div className="app__footer-cards">
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          className="app__footer-card "
        >
          <img src={images.email} alt="email" />
          <a href="mailto:hello@micael.com" className="p-text">
            shenryjr13@gmail.com
          </a>
        </motion.div>
        {/* <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          className="app__footer-card"
        >
          <img src={images.mobile} alt="phone" />
          <a href="tel:+1 (123) 456-7890" className="p-text">
            +1 (123) 456-7890
          </a>
        </motion.div> */}
      </div>

      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input
              className="p-text"
              type="text"
              placeholder="Your Name"
              name="username"
              value={username}
              onChange={handleChangeInput}
            />
          </div>
          <div className="app__flex">
            <input
              className="p-text"
              type="email"
              placeholder="Your Email"
              name="email"
              value={email}
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            type="button"
            className="p-text"
            onClick={handleSubmit}
          >
            {!loading ? "Send Message" : "Sending..."}
          </motion.button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            <span className="span-highlight">Thank you </span>for getting in
            touch!
          </h3>
        </div>
      )}
      <div className="footer__social">
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <a
            href="https://twitter.com/ankiDoodle_"
            target="_blank"
            rel="noreferrer"
          >
            <BsTwitter />
          </a>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <a
            href="https://www.linkedin.com/in/stephen-henry-520347288/"
            target="_blank"
            rel="noreferrer"
          >
            <BsLinkedin />
          </a>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <a
            href="https://github.com/StephenHenryJr"
            target="_blank"
            rel="noreferrer"
          >
            <BsGithub />
          </a>
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(Footer, "contact", "app__whitebg");
