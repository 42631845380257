import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { urlFor, client } from "../../client";
import { AppWrap } from "../../wrapper";
import { TypingText} from "../../components";

import "./About.scss";

const container = {
  visible: {
    transition: { delayChildren: 0.1, staggerChildren: 0.3 },
  },
};

const stagger = {
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  hidden: { opacity: 0, y: 100 },
};

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  return (
    <div>
      <h2 className="head-text">
        <TypingText title="About Myself" />
        <hr className="brand-line"></hr>
      </h2>
      <p className="paragraph">
        A passionate web designer and full-stack developer based in the vibrant
        city of Toronto. I thrive on bringing digital ideas to life and creating
        seamless online experiences.I'm always excited to collaborate on new
        projects and connect with fellow enthusiasts. If you have a project in
        mind or just want to grab a coffee and chat about all things tech, feel
        free to reach out. Let's turn ideas into reality!
      </p>
      <motion.div
        variants={container}
        initial="hidden"
        whileInView="visible"
        className="app__profiles"
      >
        {abouts.map((about, index) => (
          <motion.div
            variants={stagger}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>
              {about.title}
            </h2>
            <hr className="brand-line"></hr>
            <p className="p-text" style={{ marginTop: 10 }}>
              {about.description}
            </p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default AppWrap(About, "about");
