import React from "react";
import { motion } from "framer-motion";
import { BsTwitter, BsGithub, BsLinkedin } from "react-icons/bs";

const SocialMedia = () => (
  <div className="app__social">
    <motion.div
      whileHover={{ scale: 1.1 }} 
      whileTap={{ scale: 0.9 }}
    >
      <a
        href="https://twitter.com/ankiDoodle_"
        target="_blank"
        rel="noreferrer"
      >
        <BsTwitter />
      </a>
    </motion.div>
    <motion.div
       whileHover={{ scale: 1.1 }} 
      whileTap={{ scale: 0.9 }}
    >
      <a
        href="https://www.linkedin.com/in/stephen-henry-520347288/"
        target="_blank"
        rel="noreferrer"
      >
        <BsLinkedin />
      </a>
    </motion.div>
    <motion.div
      whileHover={{ scale: 1.1 }} 
      whileTap={{ scale: 0.9 }}
    >
      <a
        href="https://github.com/StephenHenryJr"
        target="_blank"
        rel="noreferrer"
      >
        <BsGithub />
      </a>
    </motion.div>
  </div>
);

export default SocialMedia;
