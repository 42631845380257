import React from "react";
import { FaArrowDown } from "react-icons/fa6";
import { motion } from "framer-motion";

import { AppWrap } from "../../wrapper";
import { ParticlesRight, TypingText} from "../../components";
import "./Header.scss";

const Header = () => (
  <div className="app__header">
    <ParticlesRight />
    <div className="header-info">
      <TypingText title="I'm Stephen Henry." />      <motion.div>
        <h1>
          Full Stack Developer <br /> Web Designer
        </h1>
      </motion.div>
    </div>
    <motion.a
      whileHover={{ scale: 1.1 }}
      className="down-arrow"
      href="#about"
    >
      <FaArrowDown size={48} color="white" />
    </motion.a>
  </div>
);

// export default AppWrap(Header, 'home');
export default AppWrap(Header, "home");
