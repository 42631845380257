import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Tooltip } from "react-tooltip";

import { AppWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Skills.scss";
import { TypingText } from "../../components";

const Skills = () => {
  const [skills, setSkills] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [certifications, setCertifications] = useState([]);

  useEffect(() => {
    const certificationsQuery = '*[_type == "certifications"]';
    const skillsQuery = '*[_type == "skills"]';
    const query = '*[_type == "experiences"]';

    client.fetch(skillsQuery).then((data) => {
      setSkills(data);
    });
    client.fetch(certificationsQuery).then((data) => {
      setCertifications(data);
    });
    client.fetch(query).then((data) => {
      setExperiences(data);
    });
  }, []);

  const container = {
    visible: {
      transition: { staggerChildren: 0.1 },
    },
  };

  const stagger = {
    visible: { x: 0, transition: { duration: 0.3 } },
    hidden: { x: 100 },
  };

  return (
    <>
      <h2 className="head-text">
        <TypingText title="Skills & Expertise" />

        <hr className="brand-line"></hr>
      </h2>

      <div className="app__skills-container">
        {/* SKILLS */}
        <motion.div
          variants={container}
          initial="hidden"
          whileInView="visible"
          className="app__skills-list"
        >
          {skills.map((skill, index) => (
            <motion.div
              variants={stagger}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: "spring" }}
              className="app__skills-item app__flex"
              key={skill.name}
            >
              <div className="app__flex">
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>

        {/* EXPERIENCE and Certifications*/}
        <motion.div
          whileInView={{ opacity: [0, 1] }}
          transition={{ duration: 3 }}
          className="app__skills-exp"
        >
          <h1>Experience</h1>
          {experiences.map((experience) => (
            <div className="app__skills-exp-item" key={experience.year}>
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <div className="app__skills-exp-works">
                {experience.works.map((work, index) => (
                  <>
                    <div className="app__skills-exp-work" key={index}>
                      <p
                        data-tooltip-id="tooltipAnchor"
                        data-tooltip-content={work.desc}
                      >
                        <h4 className="bold-text">{work.name}</h4>
                        <p className="p-text">{work.company}</p>
                      </p>
                    </div>
                    <Tooltip
                      id="tooltipAnchor"
                      place="right"
                      className="skills-tooltip"
                    ></Tooltip>
                  </>
                ))}
              </div>
            </div>
          ))}

          <h1>Certifications</h1>
          {certifications.map((certification) => (
            <div
              className="app__skills-exp-item"
              key={certification.year}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{certification.year}</p>
              </div>
              <div className="app__skills-exp-works">
                <div
                 className="app__skills-exp-work"
                >
                  <p
                    data-tooltip-id="tooltipAnchor"
                    data-tooltip-content={certification.words}
                  >
                    <h4 className="bold-text">{certification.company}</h4>
                    <p className="p-text">{certification.desc}</p>
                  </p>
                </div>
                <Tooltip
                  id="tooltipAnchor"
                  place="right"
                  className="skills-tooltip"
                ></Tooltip>
              </div>
            </div>
          ))}
        </motion.div>

      </div>
    </>
  );
};

export default AppWrap(Skills, "skills");
